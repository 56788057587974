@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");

#israjets-embedded-widget {
    p {
        padding-bottom: 0;
    }

    input,
    select,
    textarea {
        outline: none;
        border-radius: 0;

        &:invalid,
        &:required {
            box-shadow: none; // fix for FF
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a,
    button {
        cursor: pointer;
        transition: 0.25s linear;
        text-decoration: none;
        font-family: inherit;
        font-size: inherit;
        margin: 0;
        padding: 0;
        color: inherit;
        background: transparent;
        border: none;
        outline: none;

        &:disabled {
            cursor: not-allowed;
        }
    }
}
